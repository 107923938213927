import { axiosInstance } from '../utils';
import { flashActions } from './flash';
import { groupCodeConstants } from '../constants';

const getGroupCodes = ({ spNumber }) => async dispatch => {
  await axiosInstance
    .get(groupCodeConstants.API_BASE_PATH, {
      params: { sp_number: spNumber },
    })
    .then(res => {
      dispatch({ type: groupCodeConstants.GET_GROUP_CODES, payload: res.data.group_codes });
    })
    .catch(error => {
      if (error.response.status !== 404 && error.response.status !== 403) {
        dispatch(flashActions.showError(error));
      } else {
        console.log(error);
      }
    });
};

export const groupCodeActions = {
  getGroupCodes,
};
